<template>
  <DirektoratForm mode="Ubah" module="Direktorat"> </DirektoratForm>
</template>

<script>
import DirektoratForm from './form';

const DirektoratUpdate = {
  name: 'DirektoratUpdate',
  components: { DirektoratForm },
};

export default DirektoratUpdate;
</script>
